import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

export const init = () => {
  if (!process.env.SENTRY_DSN) {
    return
  }

  const integrations = []

  if (process.env.NEXT_IS_SERVER) {
    integrations.push(
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename?.replace('/var/task/', 'app:///')
          frame.filename = frame.filename?.replace('.next', '_next')
          return frame
        },
      })
    )
  } else {
    integrations.push(new Integrations.BrowserTracing())
  }

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.SENTRY_DSN,
    release: process.env.COMMIT_SHA,
    environment: process.env.ENVIRONMENT,
    integrations,
    tracesSampleRate: 1.0,
  })
}

export * from '@sentry/node'
